import { ApplicationRoute } from "src/types";
import ArticleIcon from '@mui/icons-material/Article';
import FeedIcon from '@mui/icons-material/Feed';
import TimelineIcon from '@mui/icons-material/Timeline';
import TaskIcon from '@mui/icons-material/Task';

export const routes: ApplicationRoute[] = [
  {
    path: "/",
    menu_name: "Nieuwe aanvragen",
    icon: <FeedIcon/>
  },
  {
    path: "/request",
    menu_name: "Lopende aanvragen",
    icon: <ArticleIcon/>
  },
  {
    path: "/complete-request",
    menu_name: "Afgeronde aanvragen",
    icon: <TaskIcon/>
  },
  {
    path: "/report",
    menu_name: "Rapportage",
    icon: <TimelineIcon/>
  }
];