import { useState, useEffect, useCallback } from "react";

import { useApiEndpoint } from "src/hooks";

const useRequestFetch = (status?: string): any => {
  const { fetchRequests, fetchRequestsByStatus } = useApiEndpoint();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<any>({});

  const loadRequests = useCallback(async (requestStatus?: string) => {
    setError(false);
    setLoading(true);

    try {
      const fetchedRequests = requestStatus == undefined ? await fetchRequests() : await fetchRequestsByStatus(requestStatus);
      setData(fetchedRequests);
    } catch (e: any) {
      console.error("Requests couldn't be fetched:", e.message);
      setError(true);
    }

    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await loadRequests(status);
    })();
  }, [loadRequests, status]);

  return [data, loading, error];
};

export { useRequestFetch };
