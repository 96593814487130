import { useNavigate } from 'react-router-dom';

import { ApiException } from "src/types";

type FetchConfig = {
  onAuthenticationFailed?: (e: ApiException) => void;
};

export const useFetch = ({ onAuthenticationFailed }: FetchConfig = {}) => {
  const navigate = useNavigate();

  const defaultAuthenticationFailedHandler = () => {
    console.warn("Session expired, redirect to login.");
    navigate("/error");
  };

  // eslint-disable-next-line no-undef
  return async <T>(url: string, init: RequestInit): Promise<T> => {
    const res = await fetch(url, init);

    // Handle: 401 Unauthorized & 403 Forbidden
    if ([401, 403].includes(res.status)) {
      const exception: ApiException = {
        status: res.status,
        message: "Je sessie is verlopen",
        level: "warning",
      };

      (onAuthenticationFailed || defaultAuthenticationFailedHandler)(exception);

      throw exception;
    }

    // Handle 204: No Content
    if (res.status === 204) return Promise.resolve({} as any);

    const flooredStatus = Math.floor(res.status / 100);
    if (flooredStatus === 2) return res.json();

    let message = "Unknown error";
    if (flooredStatus === 4) message = "Client error";
    if (flooredStatus === 5) message = "Server error";

    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const exception: ApiException = {
      res,
      message,
      status: res.status,
      level: "error",
    };

    throw exception;
  };
};
