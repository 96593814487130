import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { Error } from "@mui/icons-material";
import { Tooltip } from '@mui/material';
import { translateStatus } from 'src/utils';

interface TableProps {
  data: any[];
}

const Table = ({ data }: TableProps) => {
  const navigate = useNavigate();
  
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'city', headerName: 'Stad', flex: 1 },
    {
      field: 'appointmentDate',
      headerName: 'Afspraak op',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.value != null ? (new Date(params.value + "Z")).toLocaleString("nl-NL") : null
    },
    {
      field: 'createDate',
      headerName: 'Aangemaakt op',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => (new Date(params.value + "Z")).toLocaleString("nl-NL")
    },
    { 
      field: 'status',
      headerName: 'Status',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => translateStatus(params.value),
    },
    {
      field: 'declinedCount',
      headerName: '',
      width: 10,
      renderCell: (params: GridRenderCellParams) => params.value > 0 ? <Tooltip title={`Al ${params.value}x geweigerd`}><Error color="error" /></Tooltip> : ""
    }
  ];

  const onRowClick = (row: any) => {
    navigate(`/request/${row.id}`);
  }

  return (
    <div style={{ height: 'Calc(100vh - 10rem - 64px)', width: '100%' }}>
      <DataGrid sx={{}}
        rows={data}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[]}
        onRowClick={onRowClick}
      />
    </div>
  );
}

export default Table;