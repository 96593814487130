import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useForm, Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import nlLocale from 'date-fns/locale/nl';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { AnonymousRequest } from 'src/types/AnonymousRequest';
import { useEffect } from 'react';

interface AnoymousPopupComponentProps {
  show: boolean,
  hide: () => void,
  appointmentDate?: string,
  onAnonymize: (data: AnonymousRequest) => void,
}

const AnoymousPopupComponent = ({ show, hide, appointmentDate, onAnonymize }: AnoymousPopupComponentProps) => {
  const { control, register, handleSubmit, reset } = useForm<AnonymousRequest>({
    defaultValues: {
      buddy: "",
      actualAppointmentDate: appointmentDate ?? ""
    }
  });

  useEffect(() => {
    reset({
      actualAppointmentDate: appointmentDate
    });
  }, [appointmentDate]);

  const style = {
    margin: '1rem'
  };
  
  return (
    <Dialog onClose={hide} open={show}>
      <DialogTitle>
        <p style={{padding: '0 2rem', margin: 0}}>Aanvraag anonymiseren</p>
        <IconButton
            aria-label="close"
            onClick={hide}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onAnonymize)} style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <TextField
          sx={style}
          id="buddy"
          label="Buddy"
          {...register("buddy")}
          required={true}
          variant="standard"
        />
        <Controller
            name="actualAppointmentDate"
            control={control}
            render={({ field }) =>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nlLocale}>
              <DateTimePicker
                mask="__.__.____"
                ampm={false}
                renderInput={(props: any) => <TextField variant="standard" sx={style} {...props} />}
                label="Echte afspraak datum"
                {...field}
              />
            </LocalizationProvider>}
          />
        <TextField
          sx={style}
          id="notes"
          label="Notitie"
          {...register("notes")}
          required={true}
          variant="standard"
          multiline
          rows={4}
        />

        <Button sx={style} variant="contained" color="success" type="submit">Opslaan</Button>
      </form>
    </Dialog>
  );
}

export default AnoymousPopupComponent;