import React from "react";
import { useNavigate } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import { ApplicationRoute } from "src/types";

type MenuListComponentProps = {
    routes: ApplicationRoute[];
};

const MenuListComponent: React.FC<MenuListComponentProps> = ({ routes }) => {
    const navigate = useNavigate();

    return (
        <>
            <List>
                {routes.map((route, index) => (
                    <ListItem key={index} disablePadding onClick={() => navigate(route.path)}>
                        <ListItemButton>
                            <ListItemIcon>
                                    { route.icon }
                            </ListItemIcon>
                            <ListItemText primary={route.menu_name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </>
    );
};

export default MenuListComponent;
