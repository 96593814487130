import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { RequestListPage } from "src/routes/request-list/RequestListPage";
import { ReportPage } from "src/routes/report/ReportPage";
import { RequestPage } from "src/routes/request/RequestPage";
import { ErrorPage } from "src/routes/error/ErrorPage";
import { SecuredRoutes } from "src/components";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#9CC4B2'
      },
      secondary: {
        main: '#D5BBB1',
        contrastText: '#393D3F',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
        <Route path="/" element={<SecuredRoutes/>}>
          <Route index element={<RequestListPage status="new"/>} />
          <Route path="request" element={<RequestListPage/>}/>
          <Route path="request/:id" element={<RequestPage />} />
          <Route path="request/new" element={<RequestPage isNew={true}/>} />
          <Route path="complete-request" element={<RequestListPage status="done"/>} />
          <Route path="report" element={<ReportPage/>} />
          <Route path="error" element={<ErrorPage/>} />
        </Route>
        <Route path="*" element={<ErrorPage message="This is not the page your looking for."/>} />
      </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
