import { render } from 'react-dom';
import { Provider } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import App from "./App";
import "./index.css";
import store from "./state/store";

const rootElement = document.getElementById('root');
const msalInstance = new PublicClientApplication(msalConfig);

render(
  <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
  </Provider>,
	rootElement
);