import React from "react";

import {
  LoadingScreen,
  ErrorScreen,
} from "src/components";

type ReportTemplateProps = {
  loading: boolean;
  error?: boolean;
  data: any[];
};

const ReportTemplate: React.FC<ReportTemplateProps> = ({
  loading,
  error,
  data
}) => {
  return (
    <div data-testid="project-template">
      {loading && <LoadingScreen />}
      {error && <ErrorScreen message="Error" />}
      {!loading && !error && 
        <div>Nog niets te zien hier..</div>
      }
    </div>
  );
};

export default ReportTemplate;
