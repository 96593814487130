import ReportTemplate from "./ReportTemplate";
import { useReportFetch } from "./hooks/useReportFetch";

const ReportPage = () => {
  const [data, loading, error] = useReportFetch();

  return (
    <ReportTemplate
      loading={loading}
      error={error}
      data={data}
    />
  );
};

export { ReportPage };
