import { useContext } from "react";

import { useFetch } from "src/hooks/fetch/useFetch";
import { AuthContext } from 'src/state/context/AuthContext';
import { AnonymousRequest } from "src/types/AnonymousRequest";

export const useApiEndpoint = () => {
  const fetchEndpoint = useFetch();
  const auth = useContext(AuthContext);

  const fetchGet = <T>(path: string): Promise<T> =>
    fetchEndpoint(`${process.env.REACT_APP_API_URL}${path}`, { 
      method: "GET",
      headers: {
        "Content-type": "application/json;",
        "Authorization": `Bearer ${auth.token}`
      }
    });

  const fetchPost = async <T>(
    path: string,
    body: { [key: string]: any } | null = null,
    method: string = "POST"
  ): Promise<T> => {
    // Is needed because of an issue in TS lib
    // eslint-disable-next-line no-undef
    const params: RequestInit = {
      method: method,
      headers: {
        "Content-type": "application/json;",
        "Authorization": `Bearer ${auth.token}`
      },
      body: JSON.stringify(body),
    };

    return fetchEndpoint(`${process.env.REACT_APP_API_URL}${path}`, params);
  };

  return {
    fetchRequests: (): Promise<any> => fetchGet("/request"),
    fetchRequestsByStatus: (status: string): Promise<any> => fetchGet(`/request?status=${status}`),
    fetchRequest: (id: string): Promise<any> => fetchGet(`/request/${id}`),
    createRequest: (request: any): Promise<any> => fetchPost('/request', request),
    updateRequest: (id: string, request: any): Promise<any> => fetchPost(`/request/${id}`, request, 'PUT'),
    declineRequest: (id: string): Promise<any> => fetchPost(`/request/${id}/decline`, null, 'PUT'),
    anonymizeRequest: (id: string, data: AnonymousRequest): Promise<any> => fetchPost(`/request/${id}/anonymize`, data, 'PUT'),
  };
};
