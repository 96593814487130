import React, { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Alert from '@mui/material/Alert';
import nlLocale from 'date-fns/locale/nl';

import {
  LoadingScreen,
  ErrorScreen,
} from "src/components";
import { Chip } from "@mui/material";
import { Error } from "@mui/icons-material";
import { Request, StatusEnum } from "src/types";
import { AnonymousRequest } from "src/types/AnonymousRequest";
import AnoymousPopupComponent from "./components/AnoymousPopupComponent";

type RequestTemplateProps = {
  mainLoading: boolean,
  mainError?: boolean,
  data: any,
  isNew?: boolean,
  onSave: (data: Request) => void,
  saveLoading: boolean,
  saveError?: boolean,
  onRefused: () => void,
  refuseLoading: boolean,
  refuseError?: boolean,
  onAnonymize: (data: AnonymousRequest) => void,
};

const RequestTemplate: React.FC<RequestTemplateProps> = ({
  mainLoading,
  mainError,
  data,
  isNew,
  onSave,
  saveLoading,
  saveError,
  onRefused,
  refuseLoading,
  refuseError,
  onAnonymize,
}) => {
  const [open, setOpen] = useState(false);
  const { control, register, handleSubmit, reset, formState: { errors } } = useForm<Request>({
    defaultValues: {
      status: StatusEnum.New,
      appointmentDate: null
    }
  });

  const onSubmit: SubmitHandler<Request> = (data) => onSave(data);

  const openAnonymousPopup = () => {
    setOpen(true);
  }

  const textFieldStyle = {
    width: "100%",
    marginBottom: "2rem"
  };

  useEffect(() => {
    reset(data);
  }, [data]);

  return (
    <div data-testid="project-template">
      <AnoymousPopupComponent show={open} hide={() => setOpen(false)} appointmentDate={data.appointmentDate} onAnonymize={onAnonymize}/>
      {mainLoading && <LoadingScreen />}
      {mainError && <ErrorScreen message="Aanvraag kan niet worden gevonden." />}
      {!mainLoading && !mainError &&
        <>
          {
            (saveError || refuseError) && <Alert severity="error">Er is iets mis gegaan, probeer het nogmaals.</Alert>
          }
          <Card sx={{ padding: "1rem" }}>
            {
              data.declinedCount > 0 &&
              <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Chip icon={<Error />} label={`Al ${data.declinedCount}x geweigerd`} color="error" variant="outlined" />
              </Box>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                sx={textFieldStyle}
                id="name"
                label="Naam"
                {...register("name")}
                required={true}
                variant="standard"
              />
              <TextField
                sx={textFieldStyle}
                id="email"
                label="Email"
                {...register("email")}
                variant="standard"
              />

              <FormControl variant="standard" sx={textFieldStyle}>
                <InputLabel id="contactType">Contact soort</InputLabel>
                <Select
                  labelId="contactType"
                  id="contactType-select"
                  {...register("contactType")}
                  defaultValue={data.contactType}
                  required={true}
                  label="Contact soort"
                >
                  <MenuItem value={"Email"}>Email</MenuItem>
                  <MenuItem value={"SMS"}>SMS</MenuItem>
                  <MenuItem value={"Telefoon"}>Telefoon</MenuItem>
                  <MenuItem value={"WhatsApp"}>WhatsApp</MenuItem>
                </Select>
              </FormControl>

              <TextField
                sx={textFieldStyle}
                id="phoneNumber"
                label="Telefoonnummer"
                {...register("phoneNumber")}
                variant="standard"
              />

              <FormControl variant="standard" sx={textFieldStyle}>
                <InputLabel id="city">Stad</InputLabel>
                <Select
                  labelId="city"
                  id="city-select"
                  {...register("city")}
                  defaultValue={data.city}
                  required={true}
                  label="Stad"
                >
                  <MenuItem value={"Almere"}>Almere</MenuItem>
                  <MenuItem value={"Amsterdam (Oost, Sarphatistraat)"}>Amsterdam (Oost, Sarphatistraat)</MenuItem>
                  <MenuItem value={"Amsterdam (Zuid-oost, Paasheuvelweg)"}>Amsterdam (Zuid-oost, Paasheuvelweg)</MenuItem>
                  <MenuItem value={"Arnhem"}>Arnhem</MenuItem>
                  <MenuItem value={"Den Bosch (Mildred Clinics)"}>Den Bosch (Mildred Clinics)</MenuItem>
                  <MenuItem value={"Den Bosch (Vrouwen Medisch Centrum)"}>Den Bosch (Vrouwen Medisch Centrum)</MenuItem>
                  <MenuItem value={"Den Haag"}>Den Haag</MenuItem>
                  <MenuItem value={"Eindhoven"}>Eindhoven</MenuItem>
                  <MenuItem value={"Enschede"}>Enschede</MenuItem>
                  <MenuItem value={"Goes"}>Goes</MenuItem>
                  <MenuItem value={"Groningen"}>Groningen</MenuItem>
                  <MenuItem value={"Haarlem-Heemstede"}>Haarlem-Heemsteede</MenuItem>
                  <MenuItem value={"Roermond"}>Roermond</MenuItem>
                  <MenuItem value={"Rotterdam"}>Rotterdam</MenuItem>
                  <MenuItem value={"Utrecht"}>Utrecht</MenuItem>
                  <MenuItem value={"Zwolle"}>Zwolle</MenuItem>
                  <MenuItem value={"Anders"}>Anders</MenuItem>
                </Select>
              </FormControl>

              <TextField
                sx={textFieldStyle}
                id="notes"
                label="Notitie"
                {...register("notes")}
                variant="standard"
                multiline
                rows={4}
              />
              <Controller
                name="appointmentDate"
                control={control}
                render={({ field }) =>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nlLocale}>
                  <DateTimePicker
                    mask="__.__.____"
                    ampm={false}
                    renderInput={(props: any) => <TextField variant="standard" sx={textFieldStyle} {...props} />}
                    label="Afspraak op"
                    disablePast={isNew}
                    {...field}
                  />
                </LocalizationProvider>}
              />

              <FormControl variant="standard" sx={textFieldStyle}>
                <InputLabel id="status">Status</InputLabel>
                <Select
                  labelId="status"
                  id="status-select"
                  {...register("status")}
                  defaultValue={data.status}
                  disabled={data.status == "New"}
                  required={true}
                  label="Status"
                >
                  <MenuItem value={"New"}>Nieuw</MenuItem>
                  <MenuItem value={"Accepted"}>Geaccepteerd</MenuItem>
                  <MenuItem value={"Searching_Buddy"}>Buddy zoeken</MenuItem>
                  <MenuItem value={"Waiting"}>Wachten op afspraak</MenuItem>
                  <MenuItem value={"Done"}>Afgerond</MenuItem>
                </Select>
              </FormControl>
              {
                !isNew &&
                <TextField
                  sx={textFieldStyle}
                  id="createDate"
                  label="Aangemaakt op"
                  value={(new Date(data.createDate + "Z")).toLocaleString("nl-NL")}
                  variant="standard"
                  disabled
                />
              }
              <Box sx={{ width: "100%", display: "flex", justifyContent: isNew ? "flex-end" : "space-between" }}>
                {
                  (!isNew && data.status != StatusEnum.Done) && <Button variant="outlined" color="error" onClick={onRefused} disabled={refuseLoading}>Weigeren</Button>
                }
                {
                  data.status == "New" && <Button variant="contained" color="success" type="submit" disabled={saveLoading}>Accepteren</Button>
                }
                {
                  (data.status != "New" && data.status != StatusEnum.Done) && <Button variant="contained" color="success" type="submit" disabled={saveLoading}>Opslaan</Button>
                }
                {
                  data.status == StatusEnum.Done && <Button variant="outlined" color="success" type="submit" disabled={saveLoading}>Opslaan</Button>
                }
                {
                  data.status == StatusEnum.Done && <Button variant="contained" color="success" onClick={openAnonymousPopup} disabled={saveLoading}>Anonimiseren</Button>
                }
              </Box>
            </form>
          </Card>
        </>
      }
    </div>
  );
};

export default RequestTemplate;
