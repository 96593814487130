import React from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar } from "@mui/material";
import { useMsal } from "@azure/msal-react";

type UserMenuComponentProps = {
  anchorElement: null | HTMLElement;
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseMenu: () => void;
};

const UserMenuComponent: React.FC<UserMenuComponentProps> = ({ handleOpenMenu, handleCloseMenu, anchorElement }) => {
  const { instance, accounts } = useMsal();
  var username = accounts[0].name;

  const handleLogout = () => {
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  }

  return (
    <Box sx={{ flexGrow: 0, display: "flex" }}>
      <IconButton
        onClick={handleOpenMenu}
        sx={{ p: 0 }}>
        <Avatar sx={{ bgcolor: "#393D3F" }}>{username ? username[0] : ""}</Avatar>
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElement)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCloseMenu}>
          <Typography textAlign="center" onClick={handleLogout}>Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenuComponent;
