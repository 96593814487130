import { useState } from "react";

import { useApiEndpoint } from "src/hooks";
import { Request } from "src/types";

const useRequestUpdate = (): any => {
  const { updateRequest } = useApiEndpoint();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const putRequest = async (id: string, data: Request) => {
    if (data == undefined) return;
    
    var result = false;
    setError(false);
    setLoading(true);

    try {
      await updateRequest(id, data);
      result = true;
    } catch (e: any) {
      console.error("Request couldn't be updated:", e.message);
      setError(true);
    }

    setLoading(false);

    return result;
  }

  return [loading, error, putRequest];
};

export { useRequestUpdate };
