import { configureStore } from "@reduxjs/toolkit";

import requestsReducer from "./slices/requests/requestsSlice";

const store = configureStore({
  reducer: {
    requests: requestsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type StateDispatch = typeof store.dispatch;

export default store;