import { useRequestFetch } from "./hooks/useRequestFetch";
import RequestListTemplate from "./RequestListTemplate";

type RequestListPageProps = {
  status?: string;
}

const RequestListPage = ({status}: RequestListPageProps) => {
  const [data, loading, error] = useRequestFetch(status);
  
  return (
    <RequestListTemplate
      loading={loading}
      error={error}
      data={data.data}
      status={status}
    />
  );
};

export { RequestListPage };
