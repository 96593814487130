import { useState } from "react";

import { useApiEndpoint } from "src/hooks";

const useRequestRefuse = (): any => {
  const { declineRequest } = useApiEndpoint();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const refuseRequest = async (id: string) => {
    if (id == undefined) return;

    setError(false);
    setLoading(true);

    try {
      await declineRequest(id);
    } catch (e: any) {
      console.error("Request couldn't be refused:", e.message);
      setError(true);
    }

    setLoading(false);
  }

  return [loading, error, refuseRequest];
};

export { useRequestRefuse };
