import React from "react";
import { Typography } from "@mui/material";
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 2rem;
`

type ErrorTemplateProps = {
  message?: string;
}

const ErrorTemplate: React.FC<ErrorTemplateProps> = ({
  message
}) => {
  return (
    <Wrapper>
      <img src="c3p0-error.png" alt="Error" style={{maxWidth: "100%", maxHeight: "20rem"}}/>
      <Typography variant="h4">{message ?? "Er is iets mis gegaan, neem contact op met een nerd in uw omgeving."}</Typography>
    </Wrapper>
  );
};

export default ErrorTemplate;
