import ErrorTemplate from "./ErrorTemplate";

type ErrorPageProps = {
  message?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  message
}) => {
  return (
    <ErrorTemplate message={message}/>
  );
};

export { ErrorPage };
