export enum StatusEnum {
  New = "New",
  Accepted = "Accepted",
  Searching_Buddy = "Searching_Buddy",
  Waiting = "Waiting",
  Done = "Done"
}

export type Request = {
  id: number;
  name: string;
  email: string;
  contactType: string;
  phoneNumber?: string;
  city: string;
  notes?: string;
  appointmentDate: string | null;
  status: StatusEnum;
  createDate: string;
};
