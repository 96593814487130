import { useState } from "react";

import { useApiEndpoint } from "src/hooks";
import { Request } from "src/types";

const useRequestCreate = (): any => {
  const { createRequest } = useApiEndpoint();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const postRequest = async (data: Request) => {
    if (data == undefined) return;

    var result = false;
    setError(false);
    setLoading(true);

    try {
      await createRequest(data);
      result = true;
    } catch (e: any) {
      console.error("Request couldn't be created:", e.message);
      setError(true);
    }

    setLoading(false);

    return result;
  }

  return [loading, error, postRequest];
};

export { useRequestCreate };
