import { useState, useCallback } from "react";

import { Response } from "src/types";

export const useApi = <ParameterType, ResponseType>(
  apiFunction:
    | (() => Promise<ResponseType>)
    | ((args: ParameterType) => Promise<ResponseType>)
): [Response<ResponseType>, (parameters?: ParameterType) => Promise<void>] => {
  const [response, setResponse] = useState<Response<ResponseType>>({
    data: null,
    isLoading: false,
    error: null,
  });

  const resetState = () => {
    setResponse((state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
  };

  const setError = (e: any) => {
    setResponse((state) => ({
      ...state,
      isLoading: false,
      error: e.message,
    }));
  };

  const loadData = useCallback(async (parameters?: ParameterType) => {
    resetState();

    try {
      const response = await apiFunction(parameters!!);

      setResponse((state) => ({
        ...state,
        data: response,
        isLoading: false,
      }));
    } catch (e) {
      setError(e);
    }
    // We don't know which function will be put in the 'apiFunction' so we keep it out of the dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [response, loadData];
};
