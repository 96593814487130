import React from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import { ApplicationRoute } from "src/types";
import MenuList from './MenuListComponent';

type MenuComponentProps = {
    routes: ApplicationRoute[];
};

const MenuComponent: React.FC<MenuComponentProps> = ({ routes }) => {
    return (
        <Box
            component="nav"
            aria-label="mailbox folders"
        >
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, marginTop: "4rem" }
                }}
                open={true}
            >
                <MenuList routes={routes}/>
            </Drawer>
        </Box>
    );
};

export { MenuComponent as Menu };
