import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import styled from "@emotion/styled";
import React from "react";
import { Typography } from '@mui/material';

const Box = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ErrorIcon = styled(SentimentVeryDissatisfiedIcon)`
  color: #393D3F;
  height: 7rem;
  width: 7rem;
`;

type P = {
  message?: String;
};

const ErrorScreen: React.FC<P> = ({ message }) => (
  <Box>
    <ErrorIcon />
    <Typography variant="h5">{message}</Typography>
  </Box>
);

export { ErrorScreen };
