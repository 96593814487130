import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Outlet } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { AuthenticatedTemplate } from "@azure/msal-react";

import { Header, Menu } from "src/components";
import { routes } from "src/routes/routes";
import { loginRequest } from "src/authConfig";
import { AuthContext } from 'src/state/context/AuthContext';

const SecuredRoutes = () => {
  const menuRoutes = routes.filter(r => r.menu_name);
  const { instance, accounts, inProgress } = useMsal();
  const [authState, setAuthState] = useState<any>({user: null, authenticated: false, token: null});

  const login = () => {
    instance.loginRedirect(loginRequest).catch((e: any) => {
        console.error(e);
    });
  };

  const setToken = (token: string) => {
    setAuthState({
      ...authState,
      token: token
    });
  }

  const getToken = () => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      setToken(response.accessToken);
    }).catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setToken(response.accessToken);
        });
    });
  };

  useEffect(() => {
    if (inProgress != 'none') return;

    if (accounts[0])
    {
      setAuthState({
        ...authState,
        user: {
          username: accounts[0].name,
          roles: accounts[0].idTokenClaims?.roles
        },
        authenticated: true
      });

      getToken();
    }
    else {
      login();
    }
  }, [inProgress]);

  return (
    <div style={{marginTop: "64px"}}>
      <AuthenticatedTemplate>
        <AuthContext.Provider value={authState}>
          <Header routes={menuRoutes} username="Ellen" />
          <Menu routes={menuRoutes} />
          <Box
            sx={{
              marginLeft: { xs: '0', md: '240px' },
              padding: { xs: '1.5rem', md: '2rem' }
            }}
          >
            
            { authState.token && <Outlet /> }
          </Box>
        </AuthContext.Provider>
      </AuthenticatedTemplate>
    </div>
  );
};

export { SecuredRoutes };
