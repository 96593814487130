import React from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ApplicationRoute } from "src/types";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/styles';

import UserMenu from "./UserMenuComponent";
import MobileMenu from "./MobileMenuComponent";

type HeaderComponentProps = {
  routes: ApplicationRoute[];
  username?: string;
};

const HeaderComponent: React.FC<HeaderComponentProps> = ({ routes, username }) => {
  const theme = useTheme<any>();
  const [openMobileMenu, setOpenMobileMenu] = React.useState<boolean>(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = () => {
    setOpenMobileMenu(true);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setOpenMobileMenu(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu button"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'block' },
              color: '#393d3f',
              textDecoration: 'none',
            }}
          >
            Samen Naar De Kliniek
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              textAlign: "center",
              flexGrow: 1,
              color: '#393d3f',
              textDecoration: 'none',
            }}
          >
            SNDK
          </Typography>

          <MobileMenu open={openMobileMenu} handleCloseMenu={handleCloseNavMenu} routes={routes} />
          <UserMenu handleOpenMenu={handleOpenUserMenu} handleCloseMenu={handleCloseUserMenu} anchorElement={anchorElUser} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export { HeaderComponent as Header };
