import { useState } from "react";

import { useApiEndpoint } from "src/hooks";
import { AnonymousRequest } from "src/types/AnonymousRequest";

const useRequestAnonymize = (): any => {
  const { anonymizeRequest } = useApiEndpoint();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const anonymize = async (id: string, data: AnonymousRequest) => {
    if (id == undefined) return;

    setError(false);
    setLoading(true);

    try {
      await anonymizeRequest(id, data);
    } catch (e: any) {
      console.error("Request couldn't be anonymized:", e.message);
      setError(true);
    }

    setLoading(false);
  }

  return [loading, error, anonymize];
};

export { useRequestAnonymize };
