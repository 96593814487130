import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import React from "react";

const Box = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Spinner = styled(CircularProgress)`
  
`;

const LoadingScreen: React.FC = () => (
  <Box>
    <CircularProgress thickness={5} size={100}/>
    <Typography variant="h5">Loading..</Typography>
  </Box>
);

export { LoadingScreen };
