// Msal Configurations
export const msalConfig = {
    auth: {
      clientId: "b3a48f7a-66d2-4709-97d9-7ab74de4514e",
      authority: "https://login.microsoftonline.com/0e9a4548-04aa-48ad-91a0-331a94c33987",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };

export const loginRequest = {
  scopes: [
    'api://db852660-fb16-46cc-8a9f-b8ce65aae009/Request.Anonymize',
    'api://db852660-fb16-46cc-8a9f-b8ce65aae009/Request.Create',
    'api://db852660-fb16-46cc-8a9f-b8ce65aae009/Request.Decline',
    'api://db852660-fb16-46cc-8a9f-b8ce65aae009/Request.Read',
    'api://db852660-fb16-46cc-8a9f-b8ce65aae009/Request.Update'
  ]
}
