import { useState, useCallback, useEffect } from "react";

import { useApiEndpoint } from "src/hooks";

const useRequestFetch = (id?: string): any => {
  const { fetchRequest } = useApiEndpoint();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<any>({});

  const loadRequest = useCallback(async () => {
    if (id == undefined) return;

    setError(false);
    setLoading(true);

    try {
      const fetchedRequest = await fetchRequest(id);
      setData(fetchedRequest);
    } catch (e: any) {
      console.error("Request couldn't be fetched:", e.message);
      setError(true);
    }

    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id == undefined) return;

    (async () => {
      await loadRequest();
    })();
  }, [loadRequest, id]);

  return [data, loading, error, loadRequest];
};

export { useRequestFetch };
