import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "src/state/store";
import type { Request } from "src/types";

type RequestsState = {
  requests: Request[];
};

const initialState: RequestsState = {
  requests: [],
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    addRequests: (state, action: PayloadAction<Request[]>) => {
      action.payload.forEach((organization) => {
        if (!state.requests.map((o) => o.id).includes(organization.id))
          state.requests.push(organization);
      });
    },
    addRequest: (state, action: PayloadAction<Request>) => {
      if (!state.requests.map((o) => o.id).includes(action.payload.id))
        state.requests.push(action.payload);
    },
  },
});

export const { addRequest, addRequests } = requestsSlice.actions;

export const selectOrganizations = (state: RootState) =>
  state.requests.requests;

export default requestsSlice.reducer;