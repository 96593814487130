import { useParams, useNavigate } from 'react-router-dom';
import { Request, StatusEnum } from 'src/types';
import { useRequestFetch } from "./hooks/useRequestFetch";
import { useRequestCreate } from "./hooks/useRequestCreate";
import { useRequestRefuse } from "./hooks/useRequestRefuse";
import { useRequestUpdate } from "./hooks/useRequestUpdate";
import { useRequestAnonymize } from "./hooks/useRequestAnonymize";
import RequestTemplate from "./RequestTemplate";
import { AnonymousRequest } from 'src/types/AnonymousRequest';

type RequestPageProps = {
  isNew?: boolean;
}

const RequestPage = ({isNew}: RequestPageProps) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [data, mainLoading, mainError] = useRequestFetch(id);
  const [createLoading, createError, postRequest] = useRequestCreate();
  const [refuseLoading, refuseError, refuseRequest] = useRequestRefuse();
  const [updateLoading, updateError, putRequest] = useRequestUpdate();
  const [anonymizeLoading, anonymizeError, anonymizeRequest] = useRequestAnonymize();

  const onRefused = async () => {
    await refuseRequest(id);

    if (!refuseError)
      navigate("/request");
  };

  const onSave = async (data: Request) => {
    if (data.appointmentDate != null && data.appointmentDate != undefined)
      data.appointmentDate = new Date(data.appointmentDate).toISOString();

    if (data.status == StatusEnum.New)
      data.status = StatusEnum.Accepted;

    const result = id != undefined ?  await putRequest(id, data) : await postRequest(data);

    if (result)
      navigate("/request");
  };

  const onAnonymize = async (data: AnonymousRequest) => {
    await anonymizeRequest(id, data);

    if (!anonymizeError)
      navigate("/complete-request");
  };

  return (
    <RequestTemplate
      mainLoading={mainLoading}
      mainError={mainError}
      data={data}
      isNew={isNew}
      onRefused={onRefused}
      onSave={onSave}
      saveLoading={createLoading || updateLoading || anonymizeLoading}
      saveError={createError || updateError || anonymizeError}
      refuseLoading={refuseLoading}
      refuseError={refuseError}
      onAnonymize={onAnonymize}
    />
  );
};

export { RequestPage };
