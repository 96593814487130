import React from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/styles';

import MenuList from './MenuListComponent';

import { ApplicationRoute } from "src/types";

type MobileMenuComponentProps = {
    open: boolean;
    handleCloseMenu?: () => void;
    routes: ApplicationRoute[];
};

const MobileMenuComponent: React.FC<MobileMenuComponentProps> = ({ routes, open, handleCloseMenu }) => {
    const theme = useTheme<any>();

    return (
        <Box
            component="nav"
            aria-label="mobile menu"
        >
            <Drawer
                variant="temporary"
                onClose={handleCloseMenu}
                open={open}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                    zIndex: theme.zIndex.drawer + 2
                }}
            >
                <MenuList routes={routes}/>
            </Drawer>
        </Box>
    );
};

export default MobileMenuComponent;
