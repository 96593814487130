import React from "react";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";

import Table from "./components/TableComponent";
import {
  LoadingScreen,
  ErrorScreen,
} from "src/components";
import { Typography } from "@mui/material";

type RequestListTemplateProps = {
  loading: boolean;
  error?: boolean;
  data: any[];
  status?: string;
};

const getTitle = (status?: string) => {
  switch (status){
    case "done": return "Afgeronde";
    case "new": return "Nieuwe";
    default: return "Lopende";
  }
}

const RequestListTemplate: React.FC<RequestListTemplateProps> = ({
  loading,
  error,
  data,
  status
}) => {
  const navigate = useNavigate();
  return (
    <div data-testid="project-template">
      {loading && <LoadingScreen />}
      {error && <ErrorScreen message="Error" />}
      {!loading && !error && data &&
        <>
          <Typography variant="h5" sx={{marginBottom: "1rem"}}>{getTitle(status)} aanvragen</Typography>
          <Table data={data}/>
          <Fab color="primary" aria-label="add" sx={{position: "absolute", right: "1rem", bottom: "1rem"}} onClick={() => navigate("/request/new")}>
            <AddIcon />
          </Fab>
        </>
      }
    </div>
  );
};

export default RequestListTemplate;
